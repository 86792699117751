<template lang="">
  <div class="content">
    <v-app id="inspire">
      <transition name="fade">
        <div v-if="isLoading" class="loading-overlay">
          <v-row cols="12" justify="center" align="center">
            <img src="../assets/images/patients-up-10.png" alt="Loading" class="loading-image" />
          </v-row>
          <v-row cols="12">
            <v-progress-circular
              indeterminate
              class="spin-color"
              size="75"
              width="7"
            />
          </v-row>
        </div>
      </transition>
      <v-row>
        <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="cookie-patient-main">
          <div v-if="showCookiesModal" class="modal-overlay">
            <div class="modal-content">
              <v-row class="header-row" justify="space-between">
                <v-col cols="auto">
                  <v-col cols="auto">
                    <v-responsive class="sermes-logo">
                      <v-img src="@/assets/images/patient_up_logo.png" class="responsive-image" contain alt="Logo" />
                    </v-responsive>
                  </v-col>
                </v-col>
              </v-row>
              <v-row>
                <hr class="cookies-gradient-line" />
              </v-row>
              <v-row class="content-row" justify="center">
                <v-col cols="12">
                  <v-card class="form-cookies-card">
                    <v-card-title primary-title class="justify-start pt-0">
                      <h3 class="step-title">
                        {{ $t('cookie-title') }}
                      </h3>
                    </v-card-title>
                    <v-card-text class="form-cookies-fields">
                      <p> {{ $t('cookie-msg-1') }} </p>
                      <p>
                        {{ $t('cookie-msg-2') }}
                        <a target="_blank" 
                          :href="cookiesPolicyUrl" @click.stop
                          rel="noopener noreferrer">
                          {{ $t('cookie-msg-url') }}
                        </a>
                      </p>
                    <p v-html="$t('cookie-msg-3')" />
                    </v-card-text>
                    <v-card-actions class="justify-center pt-0 info-card-action">
                      <v-btn
                        x-large
                        class="modal-button-reject"
                        @click="rejectCookies"
                      >
                        {{ $t('cookie-reject') }}
                      </v-btn>
                      <v-btn
                        x-large
                        class="modal-button-accept"
                        @click="acceptCookies"
                      >
                        {{ $t('cookie-accept') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mx-auto">
          <div v-if="data.step === 1">
            <transition name="fade">
              <trial-information @update="checkStep" />
            </transition>
          </div>
          <div v-if="data.step === 2">
            <transition name="fade">
              <patient-form
                :patientData="data.patientData"
                :trialName="data.trialName"
                @update="checkPatientStep" />
            </transition>
          </div>
          <div v-if="data.step === 3 || data.step === 4">
            <transition name="fade">
              <trial-form
                @updateTrialStep="updateTrialStep"
                @trialNextStep="trialNextStep"
                @updateNotPassedSaveData="notPassedAcceptSaveData"
                @updateNotPassedNoSaveData="notPassedNoAcceptSaveData" />
            </transition>
          </div>
          <div v-if="data.step === 5">
            <transition name="fade">
              <hospital-form
                :hospitals="data.hospitals"
                @update="checkHospitalSelected" />
            </transition>
          </div>
          <div v-if="data.step === 6 && sendVerificationEmail()">
            <transition name="fade">
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="register-finished">
                  <div id="finishModal" class="finish-modal">
                    <div class="modal-finish-content">
                      <v-row>
                        <v-col class="mb-0 pb-0" cols="12" md="3" justify="left">
                          <v-img
                            class="modal-finish-logo mb-0 pb-0"
                            :src="require('../assets/images/patient_up_logo.png')"
                            height="9vh"
                            :contain="true" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <hr class="gradient-line" />
                      </v-row>
                      <v-row>
                        <h3 class="modal-finish-title">
                          {{ $t('finish-screen.register-completed-msg') }}
                        </h3>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="8">
                          <v-row>
                            <div class="modal-finish-text">
                              <p v-html="$t('finish-screen.msg')" />
                            </div>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="4">
                          <v-img
                            class="modal-finish-image"
                            :src="require('../assets/images/fiesta.png')"
                            height="30vh"
                            :contain="true" />
                        </v-col>
                      </v-row>
                      <v-row class="mb-5" justify="center">
                        <v-btn
                          class="modal-finish-button"
                          x-large
                          @click="endApp()">
                          {{ $t('finish-screen.button-finish') }}
                        </v-btn>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </transition>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <div style="position:absolute; opacity:0.85; font-size:0.9em; bottom:0; right:20px;">
            <p>
              <strong>Version: </strong>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    @click.stop
                    v-on="on"
                    rel="noopener noreferrer"
                  >
                    {{ version }}
                  </a>
                </template>
{{ sha }}
</v-tooltip>
</p>
</div>
</div>
</v-row>
</v-app>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import TrialInformation from './TrialInformation.vue';
import PatientForm from './PatientForm.vue';
import TrialForm from './TrialForm.vue';
import HospitalForm from './HospitalForm.vue';
import { trialServices } from '../services/trialService';
import getFirebaseConfig from '../utils/firebaseConfig';
import { APP_VERSION } from '../envConfig';
import { coreServices } from '../services/coreService';

export default {
  name: 'MainForm',
  components: {
    TrialInformation,
    PatientForm,
    TrialForm,
    HospitalForm,
  },
  data() {
    return {
      data: {
        step: 1,
        patientData: {},
        hospitals: [],
        trialId: '',
        trialName: '',
        dialog: true,
        doctorInfo: null,
        currentLanguage: null,
      },
      version_sha: APP_VERSION,
      version: '',
      sha: 'test',
      showCookiesModal: false,
      analyticsInitialized: false,
      cookiesPolicyUrl: coreServices.getCookiesPolicyUrl(),
      instrRd: false,
    };
  },
  created() {
    this.showCookiesModal = this.$store.state.cookiesAccepted === null;
    this.trialId = this.$route.params.trialId;
    console.log(this.trialId);
    this.addEvent('trial_id', this.trialId);
    this.extractVersionAndSha();
    this.currentLanguage = this.$store.state.selectedLanguage.toUpperCase();
    if (this.$store.state.cookiesAccepted === true) {
      this.initializeAnalytics();
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'cookiesAccepted']),
    isLoading() {
      return this.$store.state.isLoadingImages || this.$store.state.isLoadingData;
    },
    selectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    selectedLanguage(newVal) {
      this.currentLanguage = newVal;
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      if (this.analyticsInitialized) {
        logEvent(this.analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    extractVersionAndSha() {
      const [version, sha] = this.version_sha.split(' ');
      this.version = version;
      this.sha = sha ? sha.replace('(', '').replace(')', '') : '';
    },
    acceptCookies() {
      this.$store.dispatch('setCookiesAccepted', true);
      this.showCookiesModal = false;
      this.initializeAnalytics();
    },
    rejectCookies() {
      this.$store.dispatch('setCookiesAccepted', false);
      this.showCookiesModal = false;
    },
    cookieAccepted() {
      const modal = document.getElementById('cookieModal');
      modal.style.display = 'none';
    },
    checkStep(neWStep) {
      this.data.step = neWStep.step;
      this.data.trialName = neWStep.trialName;
    },
    checkPatientStep(newStep) {
      this.data.step = newStep.step;
      this.data.patientData = newStep.patientData;
    },
    updateTrialStep(newStep) {
      if (newStep.selectedAnswers) {
        this.data.hospitals = newStep.hospitals;
        this.data.patientData.questions = newStep.questions;
        this.data.patientData.question_answered = newStep.selectedAnswers;
        this.data.totalValues = this.data.patientData.question_answered.length;
      }
      this.data.step = newStep.step;
    },
    trialNextStep(newStep) {
      this.data.step = newStep.step;
      this.addEvent('form_passed', 'Patient accepted');
    },
    async notPassedAcceptSaveData() {
      await trialServices.saveDataRejectedInformation(
        this.data,
        this.trialId,
        this.currentLanguage,
      );
      this.addEvent('form_not_passed', 'Patient rejected');
      this.endApp();
      return true;
    },
    async notPassedNoAcceptSaveData() {
      await trialServices.saveDataNoAcceptRejectedInformation(
        this.data,
        this.trialId,
        this.currentLanguage,
      );
      this.addEvent('form_not_passed', 'Patient rejected');
      this.endApp();
      return true;
    },
    checkHospitalSelected(finishStep) {
      this.data.step = finishStep.step;
      this.data.patientData.hospital_selected = finishStep.hospitalSelected;
    },
    sendVerificationEmail() {
      if (this.data.step !== undefined) { // importat to avoid to send email if step is undefined by error
        trialServices.saveTrialInformation(this.data.patientData);
      }
      return true;
    },
    endApp() {
      window.location.reload();
    },
    async initializeAnalytics() {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        this.analytics = getAnalytics(app);
        this.analyticsInitialized = true;
      }
    },
  },
};
</script>
<style scoped>
.email-msg-text {
  color: #33CCCC;
  font-size: 1.1em;
  font-weight: bold;
}

.loading-overlay {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fefefe;
}

.loading-image {
  width: 30%;
  max-width: 1000px;
  object-fit: cover;
}

.spin-color {
  height: 10vh !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.fade-leave,
.fade-enter-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.spin-color {
  top: -10vh;
  color: var(--highlightedPU);
}

.cookie-patient-main {
  width: 95% !important;
  margin: 0;
  padding: 0;
}

.theme--light.v-application {
  background-color: #fff;
}

/* Cookie Modal Content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 197, 224, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  width: 70%;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
  padding: 8px;
  max-width: 800px;
  position: relative;
}

.header-row {
  width: 100%;
  padding: 0;
}

.sermes-logo {
  margin-left: 3%;
  width: auto;
  height: auto;
}

.responsive-image {
  max-width: 150px;
  height: auto;
  padding: 0;
  margin: 0;
}

.cookies-gradient-line {
  margin: auto;
  width: 89%;
  height: 3px;
  margin-top: -3vh;
  padding: 0;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.form-card {
  width: 100%;
  margin-top: 1vh;
  box-shadow: none !important;
  border: none;
  border-radius: 8px;
}

.form-fields {
  margin: 2vh 5%;
  text-align: left;
  padding-right: 10%;
}

.form-cookies-card {
  width: 100%;
  margin-top: 1vh;
  box-shadow: none !important;
  font-family: 'Poppins-Bold';
  border: none;
  border-radius: 8px;
}

.form-cookies-fields {
  margin: 2vh 5%;
  text-align: left;
  font-family: 'Roboto-Regular';
  padding-right: 10%;
}

.step-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  font-size: 20.8px;
  font-family: 'Roboto-Bold';
  color: var(--highlightedPU);
}

.cookies-message {
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Roboto-Regular';
  font-size: 16px;
  line-height: 1.7;
}

.info-card-action {
  padding-bottom: 5vh !important;
}

.modal-button-accept {
  margin-top: 2vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 200px;
  height: 42px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  border-radius: 23px;
  text-transform: uppercase;
  background: none !important;
  box-shadow: none !important;
}

.modal-button-reject {
  margin-top: 2vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 200px;
  height: 42px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  opacity: 1;
  box-shadow: none !important;
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  border-radius: 23px;
  margin-right: 9%;
  text-transform: uppercase;
  background: none !important;
  box-shadow: none !important;
}

/* End Cookie Modal Content */

/* Register Completed */
.register-finished {
  position: absolute;
  z-index: 99;
  text-align: left;
}

.finish-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(18, 197, 224, 0.4);
}

.modal-finish-content {
  background-color: #fefefe;
  margin: 0 auto;
  margin-top: 5vh;
  border: 1px solid #888;
  width: 70%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-finish-logo {
  height: 7vh;
  margin-top: 3vh;
  margin-left: 0;
  padding: 0;
}

.gradient-line {
  margin: auto;
  width: 89%;
  height: 3px;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.modal-finish-title {
  margin-top: 3vh;
  margin-left: 6%;
  font-family: 'Roboto-Regular';
  font-size: 1.3em;
  color: var(--highlightedPU);
}

.modal-finish-text {
  width: 85%;
  height: auto;
  margin-top: 3vh;
  margin-bottom: 0;
  margin-left: 9%;
  opacity: 1;
  font-family: 'Roboto-Regular';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-finish-image {
  margin: 0 auto;
  margin-top: 5vh;
  margin-right: 5vh;
}

.modal-finish-button {
  margin-top: 5vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 200px;
  height: 42px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.version-container {
  position: absolute;
  opacity: 0.85;
  font-size: 0.9em;
  bottom: 0;
  right: 20px;
}

@media (max-width: 960px) {
  .cookie-modal-content {
    width: 85%;
  }

  .register-finished {
    text-align: center;
  }

  .finish-modal {
    padding-top: 0;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 15%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 75%;
    margin-top: 3vh;
    margin-left: 15%;
    margin-right: 15%;
    height: auto;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 0vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
  }

  .modal-content {
    width: 95%;
  }

  .modal-button-accept {
    width: 185px;
    height: 45px !important;
  }

  .modal-button-reject {
    width: 185px;
    height: 45px !important;
  }

  .loading-image {
    height: 12vh !important;
    width: 90%;
  }

  .spin-color {
    margin-top: -5vh;
    height: 6vh !important;
  }

  .modal-button-accept {
    width: 130px;
    height: 35px !important;
    font-size: 0.9em;
  }

  .modal-button-reject {
    width: 130px;
    height: 35px !important;
    font-size: 0.9em;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  .cookie-modal-content {
    width: 65%;
  }

  .finish-modal {
    padding-top: 20px;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    margin-top: 3vh;
    margin-left: 8%;
    margin-right: 8%;
    height: auto;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
  }

  .modal-content {
    width: 75%;
  }

  .modal-button-accept {
    width: 150px;
    height: 35px !important;
    font-size: 0.9em;
  }

  .modal-button-reject {
    width: 150px;
    height: 35px !important;
    font-size: 0.9em;
  }
}

@media (min-width: 1265px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 55%;
  }

  .finish-modal {
    padding-top: 5vh;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 7vh;
    margin-top: 3vh;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 6%;
    font-size: 1.3em;
  }

  .modal-finish-text {
    width: 85%;
    margin-top: 3vh;
    margin-left: 9%;
    height: auto;
  }

  .modal-finish-image {
    margin: 0 auto;
    margin-top: 5vh;
    margin-right: 5vh;
    display: block;
  }

  .modal-finish-button {
    margin-top: 5vh;
    margin-bottom: 2vh;
    width: 200px;
    height: 42px !important;
    font-size: 1.1em;
  }
}
</style>
