<template>
  <v-container fluid class="main-container">
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="sermes-image-content">
        <template v-if="!isMobile">
          <v-responsive :aspect-ratio="aspectRatio" class="sermes-image">
            <v-img :src="imageSrc" class="responsive-image" contain alt="Imagen" />
          </v-responsive>
        </template>
        <template v-else>
          <v-img :src="imageSrc" class="responsive-image mobile-image" contain alt="Imagen" />
        </template>
      </v-col>
      <v-col cols="12" md="6" class="information-content">
        <v-row>
          <v-col cols="12" sm="6" class="language-icons">
            <locale-switcher />
          </v-col>
        </v-row>
        <v-row class="right-side">
          <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
            <v-card id="patient-form-card" class="form-card">
              <v-card-title primary-title class="justify-left pt-0">
                <h3 class="step-title">
                  <span id="patient-form-title">
                    {{ $t('patient-screen.title') }}
                  </span>
                </h3>
              </v-card-title>
              <v-card-text class="form-fields">
                <v-form id="patient-form" ref="form" v-model="valid" lazy-validation>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-text-field ref="input" @focus="handleFocus" @blur="handleBlur" id="patient-name"
                        class="patient-form-input" v-model="name" :error="nameError" :label="$t('patient-screen.name')"
                        :placeholder="$t('patient-screen.name-hint')" :rules="nameValidator(name)"
                        :error-messages="nameValidationError" required />
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-text-field ref="input" @focus="handleFocus" @blur="handleBlur" id="patient-surname"
                        class="patient-form-input" v-model="surname" :error="surnameError"
                        :label="$t('patient-screen.surname')" :placeholder="$t('patient-screen.surname-hint')"
                        :rules="surnameValidator(surname)" :error-messages="surnameValidationError" required />
                    </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" class="my-0 py-0">
                      <v-text-field ref="input" @focus="handleFocus" @blur="onBlur" id="patient-email"
                        class="patient-form-input" v-model="email" :error="emailBoolError" type="email"
                        :label="$t('patient-screen.email')" :placeholder="$t('patient-screen.email-hint')"
                        :error-messages="emailError" :rules="emailValidator(email)" required />
                      <span style="color:red" class="mobile-email-error-message mb-1">{{ emailError }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" sm="3" class="my-0 py-0 patient-form-input">
                      <v-select ref="input" class="my-0 py-0 patient-form-input-select" id="patient-prefix-phone"
                        v-model="prefixSelected" :error="prefixError" :items="prefixPhoneNumber" item-text="dialCode"
                        :label="$t('patient-screen.prefix')" return-object required>
                        <template v-slot:item="{ item }">
                          <img class="flag my-0 py-0" :src="item.flag" alt="flag" />{{ item.dialCode }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="9" class="my-0 py-0">
                      <v-text-field ref="input" @focus="handleFocus" @blur="handleBlur" id="patient-phone"
                        class="patient-form-input" v-model="phone" :error="phoneError"
                        :label="$t('patient-screen.phone')" :placeholder="$t('patient-screen.phone-hint')" type="tel"
                        :rules="phoneValidator(phone)" required />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-col class="py-0 my-0">
                <v-card-actions class="justify-center form-buttons">
                  <v-btn id="patient-form-previous-button" x-large class="modal-reminder-button"
                    :class="disablePrevButton ? 'button-disabled' : 'button-enabled'" @dblclick="preventDoubleClick"
                    @click="previousStep()">
                    {{ $t('patient-screen.button-prev') }}
                  </v-btn>
                  <v-btn id="patient-form-next-button" x-large class="modal-reminder-button"
                    :class="checkInvalidForm ? 'button-disabled' : 'button-enabled'" @dblclick="preventDoubleClick"
                    @click="nextStep()">
                    {{ $t('patient-screen.button-next') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import getEuropeIsoCodes from '../utils/europeIsoCodes';
import getPhoneNumberPrefix from '../utils/phoneNumberPrefix';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';
import { trialServices } from '../services/trialService';
import patientUpLogo from '../assets/images/patient_up_logo.png';
import rodilla from '../assets/images/rodilla.png';

export default {
  name: 'PatientForm',
  components: {
    LocaleSwitcher,
  },
  props: {
    trialName: {
      type: String,
      required: false,
      default: '',
    },
    patientData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', 'mdi-doctor'],
      valid: false,
      step: 2,
      prefixPhoneNumber: [],
      prefixSelected: '',
      europeCodes: [],
      trialId: '',
      emailError: '',
      nameValidationError: '',
      surnameValidationError: '',
      localPatientData: { ...this.patientData },
      nameError: false,
      surnameError: false,
      emailBoolError: false,
      phoneError: false,
      prefixError: false,
      windowHeight: window.innerHeight,
      keyboardHeight: 0,
      aspectRatio: 0.796,
      analyticsInitialized: false,
    };
  },
  created() {
    this.trialId = this.$route.params.trialId;
    this.localPatientData = this.patientData || {};
    if (this.localPatientData.name) {
      this.name = this.localPatientData.name;
      this.surname = this.localPatientData.surname;
      this.email = this.localPatientData.email;
      this.prefixSelected = this.localPatientData.prefix;
      this.phone = this.localPatientData.phoneNumber;
    }
    if (this.$store.state.cookiesAccepted === true) {
      this.initializeAnalytics();
    }
    this.addEvent('page_view', 'Patient Form view');
    console.log(this.tieneScroll(document.body));
  },
  mounted() {
    this.getAllEuropeIsoCodes();
    this.getAllPrefix();
  },
  watch: {
    '$i18n.locale': 'updateLocalizedInformation',
    email(newVal) {
      this.emailBoolError = !newVal;
    },
    prefixSelected(newVal) {
      this.prefixError = !newVal;
    },
    name(newVal) {
      this.nameError = !newVal;
    },
    surname(newVal) {
      this.surnameError = !newVal;
    },
    phone(newVal) {
      this.phoneError = !newVal;
    },
  },
  computed: {
    ...mapGetters(['cookiesAccepted']),
    nameRules() {
      return [(v) => this.nameValidator(v) || this.$t('patient-screen.valid-name')];
    },
    surnameRules() {
      return [(v) => this.surnameValidator(v) || this.$t('patient-screen.valid-surname')];
    },
    disablePrevButton() {
      /* eslint-disable-next-line */
      return this.step !== 2 ? true : false;
    },
    checkInvalidForm() {
      const nameValid = this.name && /^[a-zA-ZÀ-ÿ\s]+$/.test(this.name);
      const surnameValid = this.surname && /^[a-zA-ZÀ-ÿ\s]+$/.test(this.surname);
      return !(
        this.name !== ''
        && this.surname !== ''
        && this.emailValidator(this.email)[0] !== (this.$t('patient-screen.valid-email')
          || this.$t('patient-screen.email-exists'))
        && this.prefixSelected !== ''
        && this.phoneValidator(this.phone)[0] !== this.$t('patient-screen.valid-phone')
        && this.emailError !== this.$t('patient-screen.email-exists')
        && nameValid
        && surnameValid
      ) || this.step === this.icons.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    imageSrc() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return patientUpLogo;
      }
      return rodilla;
    },
  },
  methods: {
    updateLocalizedInformation() {
      if (this.phoneValidator(this.phone)[0] === this.$t('patient-screen.valid-phone')) {
        this.emailError = this.$t('patient-screen.email-exists');
      }
      if (this.checkEmailExists() === this.$t('patient-screen.email-exists')) {
        this.emailError = this.$t('patient-screen.email-exists');
      }
    },
    tieneScroll(elemento) {
      return elemento.scrollHeight > elemento.clientHeight;
    },
    onBlur() {
      this.handleBlur();
      this.checkEmailExists();
    },
    async addEvent(eventName, passName) {
      if (this.analyticsInitialized) {
        logEvent(this.analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    emailValidator(email) {
      if (!email) return [true];
      /* eslint-disable-next-line */
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return [re.test(String(email).toLowerCase()) || this.$t('patient-screen.valid-email')
        || this.$t('patient-screen.email-exists')];
    },
    async checkEmailExists() {
      if (!this.email) {
        this.emailError = '';
        return;
      }
      const existEmail = await trialServices.checkIfEmailExists(this.email, this.trialId);
      this.emailError = existEmail ? this.$t('patient-screen.email-exists') : '';
    },
    phoneValidator(phone) {
      const re = /^[0-9]{9}$/;
      return [re.test(String(phone)) || this.$t('patient-screen.valid-phone')];
    },
    nameValidator(name) {
      const re = /^[a-zA-ZÀ-ÿ\s]+$/;
      return [re.test(name) || this.$t('patient-screen.valid-name')];
    },
    surnameValidator(surname) {
      const re = /^[a-zA-ZÀ-ÿ\s]+$/;
      return [re.test(surname) || this.$t('patient-screen.valid-surname')];
    },
    previousStep() {
      this.step -= 1;
      this.$emit('update', { step: this.step, patientData: this.patientData });
      this.addEvent('step2_prev_buton', 'Step2 Prev Button');
    },
    nextStep() {
      const nameValid = this.name && /^[a-zA-ZÀ-ÿ\s]+$/.test(this.name);
      const surnameValid = this.surname && /^[a-zA-ZÀ-ÿ\s]+$/.test(this.surname);
      this.nameError = !nameValid;
      this.surnameError = !surnameValid;
      this.emailBoolError = !this.email || this.emailError.length > 0;
      this.phoneError = !this.phone;
      this.prefixError = !this.prefixSelected;

      if (this.nameError || this.surnameError
        || this.emailBoolError || this.prefixError || this.phoneError
        || !this.nameValidator(this.name) || !this.surnameValidator(this.surname)) {
        return;
      }

      if (this.checkInvalidForm === false) {
        this.localPatientData.name = this.name;
        this.localPatientData.surname = this.surname;
        this.localPatientData.email = this.email;
        this.localPatientData.prefix = this.prefixSelected;
        this.localPatientData.phoneNumber = this.phone;
        this.localPatientData.phone = this.prefixSelected.dialCode + this.phone;
        this.localPatientData.trial_name = this.trialName;
        this.localPatientData.trial_id = this.$route.params.trialId;

        this.step += 1;
        this.$emit('update', { step: this.step, patientData: this.localPatientData });
        this.addEvent('step2_next_buton', 'Step2 Next Button');
      }
    },
    getAllEuropeIsoCodes() {
      this.europeCodes = getEuropeIsoCodes();
    },
    getAllPrefix() {
      const ppn = getPhoneNumberPrefix();
      const esPrefix = ppn.filter((item) => item.isoCode === 'ES');
      const others = ppn.filter((item) => this.europeCodes.includes(item.isoCode) && item.isoCode !== 'ES');
      this.prefixPhoneNumber = [...esPrefix, ...others];
    },
    preventDoubleClick(event) {
      event.preventDefault();
    },
    handleFocus() {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.getElementById('patient-form-card').classList.add('keyboard-active');
      }
    },
    handleBlur() {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.getElementById('patient-form-card').classList.remove('keyboard-active');
      }
    },
    async initializeAnalytics() {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        this.analytics = getAnalytics(app);
        this.analyticsInitialized = true;
      }
    },
  },
};
</script>
<style scoped>
.main-screen {
  margin: 0;
}

.main-container {
  font-size: 1em;
  display: flex;
  flex-direction: column;
}

.information-content {
  margin: 0;
  padding: 0;
}

.language-icons {
  height: 7vh;
  margin: 1vh 0;
  padding-left: 0 !important;
  margin-left: 10px !important;
}

.sermes-image-content {
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.sermes-image {
  margin: 0;
  padding: 0;
  width: 100%;
}

.responsive-image {
  height: 100vh;
}

.right-side {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.keyboard-active #patient-form-card {
  height: 150vh !important;
}

.keyboard-active .card-buttons {
  margin-top: 40vh !important;
  border: solid green 1px;
}

.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none !important;
}

.form-fields {
  flex: 1;
  margin: auto;
  width: 80%;
  height: 50vh;
}

#patient-form {
  margin-top: 2vh;
  height: auto;
}

.patient-form-input {
  height: 15vh;
}

.patient-form-input-select {
  height: 15vh !important;
  padding-top: 0.245vh !important;
  margin-top: 2.175vh !important;
}

.step-title {
  margin-top: 3vh;
  font-size: 1em;
  height: 5vh;
  font-family: 'Roboto-Regular';
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flag {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.form-buttons {
  margin-top: 5vh;
  width: 100%;
}

#patient-form-previous-button {
  width: 180px;
  height: 40px;
  margin-right: 5%;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
}

#patient-form-next-button {
  width: 180px;
  height: 40px;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
}

.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}

.button-disabled {
  cursor: not-allowed ! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

.mobile-email-error-message {
  display: none;
}

@media (max-width: 960px) {
  .main-screen {
    padding: 0 5%;
    flex-direction: column;
  }

  .sermes-image-content {
    height: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-image {
    max-height: 10vh;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 0;
  }

  #patient-form {
    margin-top: 0;
    height: auto;
  }

  .patient-form-input {
    height: 9vh;
  }

  .patient-form-input-select {
    height: 9vh !important;
    margin-top: 2.2vh !important;
  }

  .form-buttons {
    padding-bottom: 2vh !important;
    margin-top: 0 !important;
  }

  #patient-form-previous-button,
  #patient-form-next-button {
    width: 45%;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: auto;
  }

  .responsive-image {
    height: auto;
  }

  #patient-form-previous-button,
  #patient-form-next-button {
    width: 200px;
  }
}

@media (min-width: 1265px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: 100vh;
  }

  .responsive-image {
    height: 100vh;
  }

  #patient-form-previous-button,
  #patient-form-next-button {
    width: 180px;
  }
}
</style>
