<template>
  <v-container fluid class="main-container">
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="sermes-image-content">
        <template v-if="!isMobile">
          <v-responsive class="sermes-image">
            <v-img :src="imageSrc" class="responsive-image" contain alt="Imagen" />
          </v-responsive>
        </template>
        <template v-else>
          <v-img :src="imageSrc" class="responsive-image mobile-image" contain alt="Imagen" />
        </template>
      </v-col>
      <v-col cols="12" md="6" class="form-side">
        <v-row>
          <v-col cols="12" sm="6" class="language-icons">
            <locale-switcher />
          </v-col>
        </v-row>
        <v-row class="trial-content">
          <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
            <v-card id="hospital-form-card" class="form-card">
              <v-card-title primary-title class="justify-left mt-0 pt-0 pl-0">
                <h3 class="step-title">
                  <span id="hospital-form-title">
                    {{ $t('hospital-screen.title') }}
                  </span>
                </h3>
              </v-card-title>
              <v-card-text class="hospital-content">
                <v-form id="hospital-form" ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="mx-auto">
                      <v-select id="community" class="select-community" v-model="selectedCommunity" :items="communities"
                        :label="$t('hospital-screen.select-community')" required :error="showErrors.community" />
                      <v-select id="hospital" class="select-hospital" v-model="hospitalSelected"
                        :items="filteredHospitals" item-text="name" :label="$t('hospital-screen.select-hospital')"
                        return-object required :disabled="!selectedCommunity" :error="showErrors.hospital" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="hospital-buttons justify-center">
                <v-btn id="hospital-form-finish-button" class="hospital-button"
                  :class="checkInvalidForm ? 'button-disabled' : 'button-enabled'" @click="finish()">{{
                    $t('hospital-screen.button-finish') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';
import patientUpLogo from '../assets/images/patient_up_logo.png';
import hospitalImage from '../assets/images/hospital_new.png';

export default {
  name: 'HospitalForm',
  components: {
    LocaleSwitcher,
  },
  props: {
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', '', 'mdi-doctor'],
      valid: false,
      step: 5,
      selectedCommunity: null,
      all_hospitals: {},
      hospitalNames: {},
      hospitalSelected: {},
      showErrors: {
        community: false,
        hospital: false,
      },
      aspectRatio: 1.2,
      analyticsInitialized: false,
    };
  },
  created() {
    if (this.$store.state.cookiesAccepted === true) {
      this.initializeAnalytics();
    }
    fetch('/hospitals_location.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.all_hospitals = data;
        this.hospitalNames = this.hospitals.map((hospital) => hospital.center_name.toLowerCase());
      })
      .catch((error) => {
        console.error('Error loading hospitals:', error);
      });
    this.addEvent('page_view', 'Hospital Form view');
  },
  computed: {
    ...mapGetters(['cookiesAccepted']),
    communities() {
      const hospitalCCAA = Object.entries(this.all_hospitals)
        .filter(([key]) => this.hospitalNames.includes(key.toLowerCase()))
        .map(([, value]) => value.CCAA);
      return [...new Set(hospitalCCAA)];
    },
    filteredHospitals() {
      if (!this.selectedCommunity) {
        return [];
      }
      const hospitalsInSelectedCCAA = Object.entries(this.all_hospitals)
        .filter(([key, value]) => this.hospitalNames.includes(key.toLowerCase())
          && value.CCAA === this.selectedCommunity)
        .map(([key, value]) => ({
          name: key,
          municipio: value.municipio,
          comunidad_autonoma: value.CCAA,
        }));
      return hospitalsInSelectedCCAA;
    },
    checkInvalidForm() {
      return !this.selectedCommunity || Object.keys(this.hospitalSelected).length === 0;
    },
    form() {
      return [
        this.$t('trial-screen.title'),
        this.$t('patient-screen.title'),
        this.$t('questionary-screen.title'),
        '',
        this.$t('hospital-screen.title'),
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    imageSrc() {
      if (this.isMobile) {
        return patientUpLogo;
      }
      return hospitalImage;
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      if (this.analyticsInitialized) {
        logEvent(this.analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    finish() {
      if (!this.checkInvalidForm) {
        this.step += 1;
        this.$emit('update', {
          step: this.step,
          hospitalSelected: this.hospitalSelected,
        });
        this.addEvent('step5_finish_button', 'Step5 Finish Button');
      } else {
        this.showErrors.community = !this.selectedCommunity;
        this.showErrors.hospital = Object.keys(this.hospitalSelected).length === 0;
      }
    },
    async initializeAnalytics() {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        this.analytics = getAnalytics(app);
        this.analyticsInitialized = true;
      }
    },
  },
  watch: {
    selectedCommunity(newVal) {
      if (newVal) {
        this.hospitalSelected = {};
        this.showErrors.hospital = true;
        this.showErrors.community = false;
      }
    },
    hospitalSelected(newVal) {
      if (Object.keys(newVal).length > 0) {
        this.showErrors.hospital = false;
      }
    },
  },
};
</script>
<style scoped>
.main-screen {
  margin: 0;
}

.main-container {
  font-size: 1em;
  display: flex;
  flex-direction: column;
}

.trial-content {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.sermes-logo {
  height: 12vh;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.form-side {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.language-icons {
  height: 7vh;
  margin: 1vh 0;
  padding-left: 0 !important;
  margin-left: 10px !important;
}

.sermes-image-content {
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%!important;
  margin: 0;
  padding: 0;
}

.sermes-image {
  margin: 0;
  padding: 0;
}

.responsive-image {
  height: 100vh;
  width: auto;
}

.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none !important;
}

.form-fields {
  flex: 1;
  margin: auto;
  width: 80%;
  height: 50vh;
}

.step-title {
  margin-top: 3vh;
  margin-left: 2.2%;
  font-size: 1em;
  height: 5vh;
  font-family: 'Roboto-Regular';
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none !important;
}

.hospital-content {
  margin: auto;
  margin-top: 15vh;
  height: 35vh;
  width: 85%;
  position: relative;
}

.hospital-buttons {
  margin-top: 3vh;
}

.hospital-button {
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: center;
  width: 190px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 35px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

#hospital-form-finish-button {
  font-family: 'Roboto-Bold';
  font-size: 1.1em;
  background: none !important;
  box-shadow: none !important;
  ;
}

.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}

.button-disabled {
  cursor: not-allowed ! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

@media (max-width: 960px) {
  .main-screen {
    margin-top: -8vh;
    padding: 0 5%;
    flex-direction: column;
  }

  .sermes-image-content {
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-image {
    max-height: 10vh;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 0;
  }

  .step-title {
    margin-top: 0;
    margin-left: 10%;
  }

  .language-icons {
    margin: 2vh 0 3vh 7% !important;
  }

  .hospital-content {
    margin-top: 8vh;
    height: auto;
  }

  .hospital-button {
    margin-top: 5vh;
    width: 45%;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: auto;
  }

  .responsive-image {
    height: auto;
  }
}

@media (min-width: 1265px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: 100vh;
  }

  .responsive-image {
    height: 100vh;
  }
}
</style>
