import { coreServices } from './coreService';

/* eslint-disable-next-line */
export const trialServices = {
  getTrialInformation(trialId) {
    try {
      const result = coreServices.getTrial(trialId);
      return result;
    } catch (err) {
      return [];
    }
  },
  // User accept store information
  async saveDataRejectedInformation(data, trialId, currentLanguage) {
    try {
      const { patientData } = data;
      const answers = {};
      for (let i = 0; i < patientData.questions.length; i += 1) {
        const question = patientData.questions[i];
        const answer = patientData.question_answered[i].option;
        answers[question] = answer;
      }
      const anonimousUserData = {
        phone: patientData.phone,
        language: currentLanguage,
        created_at: new Date(),
        trial_id: trialId,
        trial_name: patientData.trial_name,
        email: patientData.email,
        answers,
        accept: true,
      };
      const rejectUserData = JSON.parse(JSON.stringify(anonimousUserData));
      // anonimous user data with answers
      delete anonimousUserData.email;
      delete anonimousUserData.phone;
      delete anonimousUserData.accept;
      
      // will store the email to contact user if condition changes
      delete rejectUserData.answers;
      delete rejectUserData.phone;

      // Avoid string date
      rejectUserData.created_at = new Date();

      const result = await coreServices.saveDataRejectedTrial(anonimousUserData, rejectUserData);
      return result;
    } catch (err) {
      console.log(`Error saving rejected information: ${err}`);
      return [];
    }
  },
  // User not accept
  async saveDataNoAcceptRejectedInformation(data, trialId, currentLanguage) {
    try {
      const { patientData } = data;
      const answers = {};
      for (let i = 0; i < patientData.questions.length; i += 1) {
        const question = patientData.questions[i];
        const answer = patientData.question_answered[i].option;
        answers[question] = answer;
      }
      // save anonimous data even user not accept
      const anonimousUserData = {
        language: currentLanguage,
        created_at: new Date(),
        trial_id: trialId,
        trial_name: patientData.trial_name,
        answers,
      };
      const rejectUserData = {
        created_at: new Date(),
        trial_id: trialId,
        language: currentLanguage,
        trial_name: patientData.trial_name,
        email: patientData.email,
        accept: false,
      };
      const result = await coreServices.saveDataRejectedTrial(anonimousUserData, rejectUserData);
      return result;
    } catch (err) {
      console.log(`Error saving rejected information: ${err}`);
      return [];
    }
  },
  /* Old way */
  saveRejectedTrialInformation(patientData) {
    try {
      let formAnswered = '';
      for (let i = 0; i < patientData.questions.length; i += 1) {
        const question = patientData.questions[i];
        const answer = patientData.question_answered[i].option;
        formAnswered += `${question}:  ${answer}`;
        if (i < patientData.questions.length - 1) {
          formAnswered += ', ';
        }
      }
      const result = coreServices.saveRejectedTrial({
        name: patientData.name,
        surname: patientData.surname,
        email: patientData.email,
        phone: patientData.phone,
        trial_id: patientData.trial_id,
        trial_name: patientData.trial_name,
        created_at: new Date(),
        formAnswered,
      });
      return result;
    } catch (err) {
      return [];
    }
  },
  saveTrialInformation(trialInformation) {
    try {
      /* eslint-disable no-param-reassign */
      trialInformation.created_at = new Date();
      delete trialInformation.prefix;
      trialInformation.form_answer = trialInformation.question_answered;
      delete trialInformation.question_answered;
      coreServices.saveTrial(trialInformation);
      return 'User added';
    } catch (err) {
      return [];
    }
  },
  checkIfEmailExists(email, trialId) {
    try {
      const result = coreServices.checkTrialEmail(email, trialId);
      return result;
    } catch (err) {
      return err;
    }
  },
};
