<template>
  <v-container fluid class="main-container">
    <div class="modal-overlay">
      <div class="modal-content">
        <v-row class="header-row" justify="space-between">
          <v-col cols="auto">
            <v-responsive class="sermes-logo">
              <v-img src="@/assets/images/patient_up_logo.png" class="responsive-image" contain alt="Logo" />
            </v-responsive>
          </v-col>
          <!-- <v-col cols="auto">
            <div class="language-icons">
              <locale-switcher />
            </div>
          </v-col> -->
        </v-row>
        <v-row>
          <hr class="gradient-line" />
        </v-row>
        <v-row class="content-row" justify="center">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card id="verification-form-card" class="form-card">
              <v-card-title primary-title class="justify-start pt-0">
                <h3 class="step-title">
                  {{ $t('verification-screen.title') }}
                </h3>
              </v-card-title>
              <v-card-text class="form-fields">
                <p class="verification-message">
                  {{ $t('verification-screen.msg1') }}
                </p>
                <p class="verification-message-2">
                  {{ $t('verification-screen.msg2') }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
import { coreServices } from '../services/coreService';
// import LocaleSwitcher from '../components/LocaleSwitcher.vue';

export default {
  name: 'PatientVerification',
  components: {
    // LocaleSwitcher,
  },
  data() {
    return {
    };
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      coreServices.validateEmail(this.$route.params.trialId, this.$route.params.userId);
    },
    goHome() {
      const { trialId } = this.$route.params;
      this.$router.push({ name: 'PatientMain', params: { trialId } });
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2a96df;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
  padding: 8px;
  width: 50%;
  max-width: 800px;
  position: relative;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-row {
  width: 100%;
  padding: 0;
}

.sermes-logo {
  margin-left: 3%;
  width: auto;
  height: auto;
}

.responsive-image {
  max-width: 130px;
  height: auto;
  padding: 0;
  margin: 0;
}

.language-icons {
  width: 100%;
  padding-right: 200px;
}

.gradient-line {
  margin: auto;
  width: 89%;
  height: 3px;
  margin-top: -1vh;
  padding: 0;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.form-card {
  width: 100%;
  margin-top: 1vh;
  box-shadow: none !important;
  border: none;
  border-radius: 8px;
}

.form-fields {
  margin: 2vh 0;
  text-align: left;
}

.step-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  font-size: 20.8px;
  font-family: 'Roboto-Bold';
  color: var(--highlightedPU);
}

.verification-message {
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Roboto-Regular';
  font-size: 16px;
  line-height: 1.7;
}

.verification-message-2 {
  font-family: 'Roboto-Bold';
  font-size: 16px;
  line-height: 1.7;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 3vh;
}

@media (max-width: 960px) {
  .responsive-image {
    max-width: 150px;
  }

  .form-card {
    padding: 3vh 2vh;
  }

  .step-title {
    font-size:
      1.2em;
  }

  .modal-content {
    width: 90%;
  }

}
</style>
